.image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    // bottom: 70px;
    color: #fff;
    left: 40%; // you can modify this value to center the text
    line-height: 1;
    padding: 10px 20px;
    font-size: 17px;
    position: absolute;
    white-space: normal;
    justify-content: center;
    align-items: center;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
    // height: 200px;
    max-height: 450px !important;
}