.blogs-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
}

.blog-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    margin: 20px;
}

.blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog-content {
    padding: 20px;
}

.blog-category {
    display: inline-block;
    background: #e0e0e0;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
    margin-bottom: 10px;
}

.blog-title {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
}

.blog-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.blog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #999;
}

.follow-button {
    background: #007bff;
    border: none;
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
}

.follow-button:hover {
    background: #0056b3;
}

.see-more-button {
    background: #007bff;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
    margin-top: 20px;
}

.see-more-button:hover {
    background: #0056b3;
}

.blog-detail {
    padding: 20px;
}

.blog-image-detail {
    width: 80%; // Adjust the width as needed
    max-width: 600px; // Set a maximum width to prevent it from getting too large
    height: auto; // Maintain aspect ratio
    display: block;
    margin: 0 auto; // Center the image
}

.blog-content {
    margin-top: 20px;
}

.back-button {
    background-color: #fb9220;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 16px;
}

.back-button:hover {
    background-color: #e08b22;
}