@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,400;0,500;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Vinyl&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.landingpage_body {
  font-family: "Manrope", sans-serif;
  overflow-x: hidden;
}

.container_landing_page {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  width: 100%;
  max-width: 1920px;
}

.white-paper {
  border-radius: 6px;
  box-shadow: 0px 1px 2px #00000008;
}

.text_font_josefin {
  font-family: 'Josefin Sans', sans-serif;
}

.text_font_rubik {
  font-family: 'Rubik Vinyl', cursive;
}

.text_font_zen {
  font-family: 'Zen Dots', cursive;
}

.site-blocks-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  /* background-image:url(../public/Images/hero_bg_1.jpg) ; */
}

.site-blocks-cover.overlay {
  position: relative;
}

.site-blocks-cover.overlay:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
}

.site-blocks-cover,
.site-blocks-cover .row {
  min-height: 600px;
  height: calc(100vh);
}

.site-blocks-cover.inner-page,
.site-blocks-cover.inner-page .row {
  min-height: 600px;
  height: calc(80vh);
}

.site-blocks-cover .sub-text {
  font-size: 5.1rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: .2em;
}

.site-blocks-cover h1 {
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  font-size: 3rem;
}

.site-blocks-cover h1 strong {
  font-weight: 900;
}

@media (min-width: 768px) {
  .site-blocks-cover h1 {
    font-size: 10rem;
    font-weight: 300;
    line-height: 1.2;
  }
}

.site-blocks-cover p {
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.5;
}

.site-blocks-cover .intro-text {
  font-size: 16px;
  line-height: 1.5;
}

.img-border {
  border: 2px solid black;
  width: 184px;
  height: 308px;
}

.img-padding {
  margin-left: -19px;
  margin-top: 18px;
}

@media (max-width: 680px) {
  .img-padding {
    width: 200px;
    height: 300px;
    margin-top: 14px;
  }

  .img-border {
    width: 170px;
    height: 330px;
  }
}