.text-underline {
    width: 65px;
    border: 1px solid;
}
.text-underline-hrs {
    border: 1px solid;
}
.office-detail {
    width: 60%;
}
.days-detail {
    margin-right: 200%;
}
.heading-fonts {
    font-size: 37px;
}
.section-hours {
    h5 {
        font-size: 20px;
        color: black;
        line-height: 32px;
    }
}
.address-line {
    font-size: 21px;
    line-height: 37px;
}
.container-office-section {
    padding: 108px;
}
.dark-orange-text {
    color: black;
}
.light-orange-text {
    color: black;
}
.top-margin {
    margin-top: 75px;
}
.contentWrapper {
  height: 80%;
  width: 80%;
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  align-content: center;
  justify-content: center;
}