.team_member_bg_right {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(../../image/OIP.png);
}

.team_member_bg_left {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(../../image/OIPLeft.png);
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 225px;
    height: 300px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #c6cfdc;
    box-shadow: 0 0 15px #c6cfdc;
    color: #808080;
    transition: transform 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;

    @media (max-width: 768px) {
        width: 180px;
        height: 240px;
    }

    @media (max-width: 480px) {
        width: 150px;
        height: 200px;
    }

    &:hover {
        transform: scale(1.05);
        color: #ff8c00;
        box-shadow: 0 0 20px #c6cfdc;
        border: 1px solid #ff8c00;
    }
}

.card__image {
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 16px;

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    @media (hover: hover) {
        img {
            transition: transform 0.3s ease;
        }

        .card:hover & img {
            transform: scale(1.05);
        }
    }
}

.card__content {
    text-align: center;
}

.card__name {
    font-size: 18px;
    margin-bottom: 5px;
}

.card__title {
    font-size: 14px;
    color: #888;
    margin-bottom: 0;
    &:hover {
        color: black;
    }
}

.team_card_grid {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 50px 64px;
}

@media (max-width: 1023px) {
    .team_card_grid {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px 100px;
    }
}

@media (max-width: 767px) {
    .team_card_grid {
        grid-template-columns: repeat(1, 1fr);
    }
}