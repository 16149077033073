.video-background {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

.video-background video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    /* Add a semi-transparent background for better text visibility */
}

.video-overlay h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.video-overlay p {
    font-size: 1.2rem;
}