.location-with-img-background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    // background: url("https://atldentalspa.wpenginepowered.com/wp-content/uploads/2019/08/ads-hero-aug-19.mp4");
    background-image: url(../../../public/Images/location_with_bg.png);
}

.location-with-image-height {
    max-height: 380px;
}

.site-blocks-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url(../../../public/Images/hero_bg_1.jpg);
}

.site-blocks-cover.overlay {
    position: relative;
}

.site-blocks-cover.overlay:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
}

.site-blocks-cover,
.site-blocks-cover .row {
    min-height: 600px;
    height: calc(100vh);
}

.site-blocks-cover.inner-page,
.site-blocks-cover.inner-page .row {
    min-height: 600px;
    height: calc(80vh);
}

.site-blocks-cover .sub-text {
    font-size: 5.1rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    letter-spacing: .2em;
}

.site-blocks-cover h1 {
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    font-size: 3rem;
}

.site-blocks-cover h1 strong {
    font-weight: 900;
}

@media (min-width: 768px) {
    .site-blocks-cover h1 {
        font-size: 10rem;
        font-weight: 300;
        line-height: 1.2;
    }
}

.site-blocks-cover p {
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.5;
}

.site-blocks-cover .intro-text {
    font-size: 16px;
    line-height: 1.5;
}