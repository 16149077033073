.card-grid {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px 64px;
}
.card-body {
  border-radius: 32px;
  width: 225px;
  height: 225px;
  border: 1px solid #c6cfdc;
  box-shadow: 0 0 15px #c6cfdc;
  color: #808080;
  transition: transform 0.3s ease;
}
.card-body:hover {
  transform: scale(1.2);
  color: #ff8c00;
  box-shadow: 10px 15px 25px #c6cfdc;
  border: 2px solid #ff8c00;
}

// svg:hover style {
//     .st1:hover, .st2:hover, .st3:hover, .st4:hover, .st5:hover, .st6:hover, .st7:hover {
//         fill: orange;
//       }
// }
// .card-body:nth-child(even):hover {
//   border-color: black;
// }

// .card-body:nth-child(odd):hover {
//   border-color: #ff8c00;
// }
// .svg img {
//     fill: orange !important;
// }

@media (max-width: 1023px) {
    .card-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px 100px;
      }
}
@media (max-width: 767px) {
    .card-grid {
        grid-template-columns: repeat(1, 1fr);
      }
}